<script lang="ts" setup>
import { MoonIcon, SunIcon } from '@heroicons/vue/24/outline';
import { useDarkMode } from '@/stores/darkMode.ts';

const darkMode = useDarkMode();

const toggleDarkMode = (): void => {
  darkMode.toggle();
};

</script>
<template>
  <label
    class="swap swap-rotate fixed bottom-4 right-4 z-50 p-2 rounded-full bg-white shadow-md cursor-pointer print:hidden"
  >
    <input
      class="theme-controller"
      type="checkbox"
      @click="toggleDarkMode"
    >
    <MoonIcon class="swap-on size-5 fill-blue-400"/>
    <SunIcon class="swap-off size-5 fill-amber-400"/>
  </label>
</template>

<style lang="css" scoped>
</style>
