<script lang="ts" setup>
const hash = 'a65e49ddbbf728eebc35654cf4e496885b7429206136d4e023a312456c3dc532';

const gravatar = new URL('https://www.gravatar.com/avatar/' + hash + '.jpg?s=512', import.meta.url).href;
const qr = new URL('https://www.gravatar.com/' + hash + '.qr?s=512', import.meta.url).href;

</script>

<template>
  <div class="bg-base-100 h-screen w-screen content-center text-primary">
    <div class="flex flex-wrap justify-center">
      <div class="flip-container cursor-pointer size-48">
        <div class="flipper">
          <div class="front">
            <img
              :src="gravatar"
              alt="Joy Lazari Gravatar"
              class="rounded-full size-48"
            >
          </div>
          <div class="back">
            <div class="rounded-full bg-base-100 p-4 size-48 shadow-md content-center">
              <img
                :src="qr"
                alt="Joy Lazari QR code"
                class="aspect-square size-32 m-auto"
              >
            </div>
          </div>
        </div>
      </div>
      <h1 class="text-3xl font-semibold text-center w-full p-4">
        Joy Lazari
      </h1>
    </div>
  </div>
</template>

<style scoped>
.flip-container {
  perspective: 1000px;
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}

.flip-container:hover .flipper {
  transform: rotateY(180deg);
}

.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.front {
  z-index: 2;
  transform: rotateY(0deg);
}

.back {
  transform: rotateY(180deg);
}
</style>
