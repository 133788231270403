import { defineStore } from 'pinia';
import { RemovableRef, useLocalStorage } from '@vueuse/core';

export interface State {
  isDark: RemovableRef<boolean>;
}

export const useDarkMode = defineStore('darkMode', {
  state: (): State => ({
    isDark: useLocalStorage('isDark', false),
  }),
  getters: {
    isDarkMode: state => state.isDark,
  },
  actions: {
    toggle() {
      this.isDark = !this.isDark;
    },
  },
});
