<script lang="ts" setup>
import { RouterView } from 'vue-router';
import { computed } from 'vue';
import DarkModeSelector from '@/components/common/DarkModeSelector.vue';
import { useDarkMode } from '@/stores/darkMode';

const darkMode = useDarkMode();

const isDark = computed(() => darkMode.isDarkMode);

</script>

<template>
  <div
    :class="isDark ? 'dark' : 'light'"
    :data-theme="isDark ? 'dark' : 'light'"
  >
    <DarkModeSelector/>
    <RouterView/>
  </div>
</template>

<style lang="css" scoped>
/* Force light mode for print */
@media print {
  :root, [data-theme=dark], :root:has(input.theme-controller[value=dark]:checked) {
    color-scheme: light;
    --in: 72.06% 0.191 231.6;
    --su: 64.8% 0.150 160;
    --wa: 84.71% 0.199 83.87;
    --er: 71.76% 0.221 22.18;
    --pc: 89.824% 0.06192 275.75;
    --ac: 15.352% 0.0368 183.61;
    --inc: 0% 0 0;
    --suc: 0% 0 0;
    --wac: 0% 0 0;
    --erc: 0% 0 0;
    --rounded-box: 1rem;
    --rounded-btn: 0.5rem;
    --rounded-badge: 1.9rem;
    --animation-btn: 0.25s;
    --animation-input: .2s;
    --btn-focus-scale: 0.95;
    --border-btn: 1px;
    --tab-border: 1px;
    --tab-radius: 0.5rem;
    --p: 49.12% 0.3096 275.75;
    --s: 69.71% 0.329 342.55;
    --sc: 98.71% 0.0106 342.55;
    --a: 76.76% 0.184 183.61;
    --n: 32.1785% 0.02476 255.701624;
    --nc: 89.4994% 0.011585 252.096176;
    --b1: 100% 0 0;
    --b2: 96.1151% 0 0;
    --b3: 92.4169% 0.00108 197.137559;
    --bc: 27.8078% 0.029596 256.847952
  }
}
</style>
