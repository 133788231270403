import { computed, ComputedRef } from 'vue';

const encodeEmail = (email: string): string => email
  .split('')
  .map((char: string) => `&#${char.charCodeAt(0)};`)
  .join('');

const useEmail = (email: string): ComputedRef<string> => {
  return computed(() => {
    return `${encodeEmail(email.split('@')[0])}@${encodeEmail(email.split('@')[1])}`;
  });
};

export default useEmail;
