<script lang="ts" setup>
import { Language, LanguageFluency } from '@/data/resume';

const props = defineProps<{
  languages: Language[];
}>();

const motherTongues = props.languages.filter(l => l.fluency === LanguageFluency.Native);
const otherLanguages = props.languages.filter(l => l.fluency !== LanguageFluency.Native);
</script>

<template>
  <div class="py-2">
    <div>
      Mother tongue{{ motherTongues.length > 1 ? 's' : '' }}:
      <span
        v-for="language in motherTongues"
        :key="language.language"
        itemprop="knowsLanguage"
        itemscope
        itemtype="https://schema.org/Language"
      >
        <span class="font-bold uppercase">{{ language.language }}</span>
        <span v-if="motherTongues.indexOf(language) !== motherTongues.length - 1">, </span>
      </span>
    </div>
    <div>Other language{{ otherLanguages.length > 1 ? 's' : '' }}:</div>
    <table class="text-center w-full mt-5">
      <thead>
      <tr class="border-b border-gray-200">
        <th class="p-1 font-bold uppercase">&nbsp;</th>
        <th class="p-1 font-bold uppercase" colspan="2">Understanding</th>
        <th class="p-1 font-bold uppercase" colspan="2">Speaking</th>
        <th class="p-1 font-bold uppercase">Writing</th>
      </tr>
      <tr class="border-b border-gray-200">
        <th class="p-1 font-normal">&nbsp;</th>
        <th class="p-1 font-normal">Listening</th>
        <th class="p-1 font-normal">Reading</th>
        <th class="p-1 font-normal">Spoken<br>production</th>
        <th class="p-1 font-normal">Spoken<br>interaction</th>
        <th class="p-1 font-normal">&nbsp;</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="language in otherLanguages" :key="language.language"
          class="border-b border-gray-200 even:bg-white odd:bg-gray-100"
      >
        <td class="p-1 text-left font-bold uppercase">
          <span
            itemprop="knowsLanguage"
            itemscope
            itemtype="https://schema.org/Language"
          >{{ language.language }}</span>
        </td>
        <td class="p-1 text-center">{{ language.levels.listening }}</td>
        <td class="p-1 text-center">{{ language.levels.speaking }}</td>
        <td class="p-1 text-center">{{ language.levels.spokenInteraction }}</td>
        <td class="p-1 text-center">{{ language.levels.spokenProduction }}</td>
        <td class="p-1 text-center">{{ language.levels.writing }}</td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td class="text-left text-xs font-light italic" colspan="6">Levels: A1 and A2: Basic user; B1 and B2:
          Independent user; C1 and C2: Proficient user
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</template>

<style scoped>

</style>
