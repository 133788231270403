export type DateFormatOptions = {
  day?: 'numeric' | '2-digit';
  month?: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow';
  year?: 'numeric';
};

export type PeriodFormatOptions = {
  style: 'compact' | 'full' | 'europass';
}

const useDate = (date?: string, formatOptions?: DateFormatOptions): string => {
  if (!date) return 'Current';
  return new Date(date).toLocaleDateString('da-DK', formatOptions);
};

export const usePeriod = (start: string, end?: string, formatOptions: PeriodFormatOptions = { style: 'full' }): string => {
  if (formatOptions.style === 'compact') {
    const startDate = useDate(start, { year: 'numeric' });
    const endDate = useDate(end, { year: 'numeric' });
    if (startDate === endDate) return startDate;
    return `${startDate} – ${endDate}`;
  }
  return `${useDate(start)} – ${useDate(end)}`;
};

// noinspection JSUnusedGlobalSymbols
export default useDate;
