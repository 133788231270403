import resumeJson from './resume.json';

export type Location = {
  address: string;
  postalCode: string;
  city: string;
  countryCode: string;
  region: string;
};

export type Profile = {
  network: string;
  username: string;
  url: string;
};

export type Basics = {
  name: string;
  label: string;
  picture: string;
  email: string;
  phone: string;
  website: string;
  summary: string;
  location: Location;
  profiles: Profile[];
};

export type Highlight = string;

export type Work = {
  company: string;
  position: string;
  website: string;
  startDate: string;
  endDate: string;
  location: string;
  summary: string;
  highlights: Highlight[];
  logo: string;
};

export type Volunteer = {
  organization: string;
  position: string;
  website: string;
  startDate: string;
  endDate: string;
  location: string;
  summary: string;
  highlights: Highlight[];
};

export type Course = string;

export type Education = {
  institution: string;
  area: string;
  studyType: string;
  startDate: string;
  endDate: string;
  location: string;
  gpa: string;
  courses: Course[];
  logo: string;
};

export type Award = {
  title: string;
  date: string;
  awarder: string;
  summary: string;
};

export type Publication = {
  name: string;
  publisher: string;
  releaseDate: string;
  website: string;
  summary: string;
};

export type Keyword = string;

export type Skill = {
  name: string;
  level: string;
  keywords: Keyword[];
};

// noinspection JSUnusedGlobalSymbols
export enum LanguageFluency {
  Native = 'Native',
  ProfessionalWorking = 'Professional Working',
  FullProfessional = 'Full Professional',
  LimitedWorking = 'Limited Working',
  Elementary = 'Elementary',
}

// noinspection JSUnusedGlobalSymbols
export enum LanguageLevel {
  A1 = 'A1',
  A2 = 'A2',
  B1 = 'B1',
  B2 = 'B2',
  C1 = 'C1',
  C2 = 'C2',
}

export type Language = {
  language: string;
  fluency: LanguageFluency;
  levels: {
    listening: LanguageLevel;
    speaking: LanguageLevel;
    spokenInteraction: LanguageLevel;
    spokenProduction: LanguageLevel;
    writing: LanguageLevel;
  };
};

export type Interest = {
  name: string;
  keywords: Keyword[];
};

export type Reference = {
  name: string;
  reference: string;
};

type Resume = {
  basics: Basics;
  work: Work[];
  volunteer: Volunteer[];
  education: Education[];
  awards: Award[];
  publications: Publication[];
  skills: Skill[];
  languages: Language[];
  interests: Interest[];
  references: Reference[];
};

export const resume = resumeJson as unknown as Resume;
