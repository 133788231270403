import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import ResumeView from '@/views/ResumeView.vue';
import ErrorView from '@/views/ErrorView.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: { title: 'Joy Lazari' },
    },
    {
      path: '/resume',
      name: 'resume',
      component: ResumeView,
      meta: { title: 'Resume' },
    },
    {
      path: '/resume/europass',
      name: 'europass',
      component: ResumeView,
      props: { europass: true },
      meta: { title: 'Europass Resume' },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'error',
      component: ErrorView,
      props: { code: 404 },
    }
  ],
});

declare module 'vue-router' {
  // noinspection JSUnusedGlobalSymbols
  interface RouteMeta {
    title: string;
  }
}

router.beforeEach((to, _, next): void => {
  document.title = to.meta.title;
  next();
});

export default router;
