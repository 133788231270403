<script lang="ts" setup>
import useEmail from '@/composables/useEmail.ts';
import { resume } from '@/data/resume.ts';

const email = useEmail(resume.basics.email);
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <span
    class="blur-[2px] hover:blur-0 print:blur-0"
    itemprop="email"
    v-html="email"
  />
  <!-- eslint-enable vue/no-v-html -->
</template>

<style scoped>

</style>
